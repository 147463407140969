import fetchData from './fetchData.js';
import validation from './inputValidation.js';

//Set Use Strict
"use strict";

//PATHNAME Default
const PATHNAME = window.location.pathname;

//Get Url Base
const HREF = window.location.origin;

//Get Token
const TOKEN = document.querySelector('meta[name="csrf-token"]').content;

//Input Validation Method
function inputValidationMethod(validationtype, inputvalue, inputid = ''){

    //Instance Validation
    let inputValidation = new validation(validationtype, '', '');

    //Validation Method
    let validInput;

    //Set Method
    switch(validationtype)
    {
        //Input Validation only for numbers
        case 2:
            validInput = inputValidation.validNameCases(validationtype, inputvalue);
        break;

        //Input Validation All Types of data
        case 4:
            validInput = inputValidation.validNameCases(validationtype, inputvalue);
        break;

        //Only Name
        case 6:

            let onlyname = new RegExp(/^[A-Z-a-z-À-ü( )\ç\s]+$/, "gm");
            validInput = onlyname.test(inputvalue);

        break;

        //Email Validation
        case 7:

            let emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
            validInput = emailRegex.test(inputvalue);

        break;

        //URL Validation
        case 8:
            validInput = inputValidation.isValidUrl(inputvalue, inputid);
        break;

    }

    return validInput;

};

//Dynamic Generate Error Message
function dynamicErrorMessageGenerate(listIdInputs, valuelengtherror = false){

    //Show Error Messages
    if(listIdInputs.length > 0){

        // console.log(listIdInputs);

        //Set id for error message
        listIdInputs.forEach((value) => {

            //Instance Validation
            //let inputValidation = new validation(3, '', '');

            //Get Label
            let label = document.getElementById(value).previousElementSibling.innerHTML.split(' ')[0].toLowerCase();

            // Check if is required
            let isRequired = document.getElementById(value).hasAttribute('required') && document.getElementById(value).value === "";

            //Default Message
            let message = isRequired ? "Preencha este campo obrigatório." : `Por favor, insira um ${label} válido.`;

            //Render Error Message
            if(valuelengtherror){

                message = `Não é permitido esta quantidade de caracters em ${label}.`;

            }

           // let allerrorelementts = [...document.querySelectorAll('div.invalid-feedback')];

            //Get Element to Apeend
            let input = document.getElementById(value);

            //Create Element
            let spanError = document.createElement('div');

            //Set Attribute for the element
            spanError.setAttribute('class', 'invalid-feedback');
            spanError.innerText = message;

            //Create Element After Input
            input.after(spanError);

            //Default Class
            let className = isRequired ? " is-invalid is-empty" : " is-invalid";

            //Input Class Error
            document.getElementById(value).className += className;


        });

        //Clean Variable
        listIdInputs= [];

        //Disabled Submit
        //this.disabled = true;

        //Disable Loading and enable submit
        //disableLoadingAndEnableBtn();

        return listIdInputs;

    }

}

//Focus Event function for all Inputs
function focusEventValidation(allInputs){

    //Focus Event
    allInputs.forEach((valinput) => {

        //Check input type attribute
        if(valinput.getAttribute('type') !== "file"){

            valinput.addEventListener('focusout', function(){

                valinput.classList.remove('is-invalid', 'is-empty');

                if(valinput.nextSibling){

                    valinput.nextSibling.remove();

                }

                //Check Value
                if(valinput.getAttribute('id') !== "work-phone" && valinput.getAttribute('id') !== "work-portfolio-link"){

                    //Validation Input
                    let inputvalue = inputValidationMethod(parseInt(valinput.getAttribute('data-validate')), valinput.value, valinput);

                    if(!inputvalue){

                        //Disable Submit Button
                        document.querySelector('.submitbutton').setAttribute('disabled', true);

                        return dynamicErrorMessageGenerate([valinput.getAttribute('id')]);

                    }else{

                        //Enabled Submit Button
                        document.querySelector('.submitbutton').removeAttribute('disabled');

                    }

                }

            });

        }

    });

}

//Clean all error messages
function cleanErrorMessages(){

    //Clean all Error Messages
    [...document.querySelectorAll('div.invalid-feedback')].forEach((value) => {
        
        //Remove element
        value.remove();

    });

}

//Clean all Input Class Error
function cleanInputClassError(){

    [...document.querySelectorAll('.form-control')].forEach((value) => {
        
        value.classList.remove('is-invalid', 'is-empty');

    });

}

//Response Structure
function responseStructure(value, allInputs, classname = "formsenddata"){

    //Get Data from Message
    const [valresponse] = Object.entries(value);

    //Only Clean Input when the response is success
    if(valresponse[0].toLowerCase() !== 'errormessage'){

        //Clean Input
        allInputs.forEach((valinput) => {

            //Clean Values
            valinput.value = "";
            
    
        });

    }

    //Remove Message
    if(document.querySelector('.spanresponsemessage')){

        document.querySelector('.spanresponsemessage').remove();

    }
   
    //Element to show message from response
    let spanmessage = document.createElement('span');

    //Set Class for type of Response
    spanmessage.setAttribute('class', valresponse[0].toLowerCase());
    spanmessage.classList.add('spanresponsemessage');

    //Set Message from response
    spanmessage.textContent = valresponse[1].join("");

    //Render Message
    document.querySelector(`.${classname}`).before(spanmessage);

    //Clean Message
    setTimeout(() => {

        //Remove Message
        document.querySelector('.spanresponsemessage').remove();

    }, 5000);

    return;

}

//Enable Loading and disable submit
function enabledLoadingAndDisableBtn(){

    //Enable Loading
    document.querySelector('.loadingbase').classList.add('d-flex');

    //Disable Submit Button
    document.querySelector('.submitbutton').setAttribute('disabled', true);

}

//Disable Loading and enable submit
function disableLoadingAndEnableBtn(){

    //Disabled Loading
    document.querySelector('.loadingbase').classList.remove('d-flex');

    //Enabled Submit Button
    document.querySelector('.submitbutton').removeAttribute('disabled');

}

//Get Filedata
function getFiledata(e, nameinput, filedataobjc, alertgigabyte, fileExtensionApproved){
    
    //Set Data
    filedataobjc.name = nameinput;
    filedataobjc.filename = e.files[0].name;
    filedataobjc.type = e.files[0].type.replace('application/', '');
    filedataobjc.file64 = '';

    //Check File size
    if(e.files[0].size >= alertgigabyte){

        //Error Message
        alert("Favor anexar um ficheiro menor que 2GB");

        return;

    }

    //Check File extension
    if(!fileExtensionApproved.includes(filedataobjc.type)){

        //Error Message
        alert("Favor apenas anexar extensões de ficheiros permitidas");

        return;

    }

    //Get base64 Promise
    let file64Promise = fetchData.toBase64(e.files[0]);

    //Get Data from Promise
    file64Promise.then((val64) => {

        //Set file64
        filedataobjc.file64 = val64;

        return filedataobjc;

    }).catch((error) => {

        throw new Error(error);

    });

    return filedataobjc;

}

//Event Created
(function(){

    //Slide Carousel
    if(document.querySelector('.logos-slider')){

        $('.logos-slider').slick({
            dots: false,
            infinite: true,
            autoplay: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                }
            ]
        });

    }

    //Data to Send
    let dataForm = {};

    //Get all Inputs
    let allInputs = [...document.querySelectorAll('.form-control')];

    //Listid input errors
    let inputiderros = [];
    let valuelengtherror = false;

    //Contacts Route
    if(PATHNAME.includes('contactos')){

        //Post URL Contact
        const URLMETHODCONTACTS = HREF + "/contactos";

        //Check all inputs by focus event
        focusEventValidation(allInputs);
       
        //Submit Form Data
        document.querySelector('.formsendcontacts').addEventListener('submit', function(e){

            //Prevent Default
            e.preventDefault();

            //Clean List of errors
            inputiderros = [];
            dataForm = {};

            //Enable Loading and disable submit
            enabledLoadingAndDisableBtn();
        
            //Clean all error messages
            cleanErrorMessages();
        
            //Clean all Input Class Error
            cleanInputClassError();
        
            //Get Formdata
            allInputs.forEach((valinput) => {
        
                //Validation Input
                let inputvalueerror = inputValidationMethod(parseInt(valinput.getAttribute('data-validate')), valinput.value, valinput);

                //Set Values from Inputs
                switch(valinput.getAttribute('name')){

                    //Firstname
                    case 'contact-firstname':

                        dataForm.firstname = valinput.value.trim();

                        //Check Lenght
                        if(valinput.value.trim().length > 150){

                            inputvalueerror = false;
                            valuelengtherror = true;

                        }

                    break;

                    //Lastname
                    case 'contact-lastname':

                        dataForm.lastname = valinput.value.trim();

                        //Check Lenght
                        if(valinput.value.trim().length > 150){

                            inputvalueerror = false;
                            valuelengtherror = true;

                        }

                    break;

                    //Email
                    case 'contact-email':

                        dataForm.email = valinput.value.trim();

                        //Check Lenght
                        if(valinput.value.trim().length > 300){

                            inputvalueerror = false;
                            valuelengtherror = true;

                        }

                    break;

                    //Message
                    case 'contact-message':

                        dataForm.message = valinput.value.trim();

                        //Check Lenght
                        if(valinput.value.trim().length > 500){

                            inputvalueerror = false;
                            valuelengtherror = true;

                        }

                    break;

                }

                //Push input id when is not have a valid value
                if(!inputvalueerror){
        
                    inputiderros.push(valinput.getAttribute('id'));

                }
        
            });
        
            //Check Errors
            if(inputiderros.length > 0){

                //Disable Loading and enable submit
                disableLoadingAndEnableBtn();
                
                dynamicErrorMessageGenerate(inputiderros, valuelengtherror);

                return;
        
            }

            //Get All data from Inputs
            if(Object.values(dataForm).length > 0){

                //Set RGPD
                dataForm.rgpd = 1;

                //Send Data
                let response = fetchData.structureTosendData(URLMETHODCONTACTS, dataForm, TOKEN, "POST");

                //Check Response
                response.then((value) => {

                    //Disable Loading and enable submit
                    disableLoadingAndEnableBtn();

                    return responseStructure(value, allInputs);
                 

                }).catch((error) => {

                    //Disable Loading and enable submit
                    disableLoadingAndEnableBtn();

                    throw new Error(error);

                });

            }
           
            return;

        });

    }

    //Workwithus Route
    if(PATHNAME.includes('trabalha-connosco')){

        //Post URL Contact
        const URLMETHODWORKWITHUS = HREF + "/trabalha-connosco";

        //Alert Gigabyte
        let alertgigabyte = 1024 * 1024 * 1000 * 2;

        //Check Fileextension
        let fileExtensionApproved = ['pdf'];

        //Get file Data from work-cv
        let workcvfile = {};

        //Get file Data from work-cv
        let workportfolio = {};

        //Select Conditional | choose area
        $('select').each(function(){
            let $this = $(this), numberOfOptions = $(this).children('option').length;
        
            $this.addClass('select-hidden');
            $this.wrap('<div class="select"></div>');
            $this.after('<div class="form-select"></div>');
        
            let $styledSelect = $this.next('div.form-select');
            $styledSelect.text($this.children('option').eq(0).text());
        
            let $list = $('<ul />', {
                'class': 'select-options'
            }).insertAfter($styledSelect);
        
            for (let i = 0; i < numberOfOptions; i++) {
                $('<li />', {
                    text: $this.children('option').eq(i).text(),
                    rel: $this.children('option').eq(i).val()
                }).appendTo($list);
                if ($this.children('option').eq(i).is(':selected')){
                    $('li[rel="' + $this.children('option').eq(i).val() + '"]').addClass('is-selected')
                }
            }
        
            let $listItems = $list.children('li');
        
            $styledSelect.click(function(e) {
                e.stopPropagation();
                $('div.form-select.active').not(this).each(function(){
                    $(this).removeClass('active').next('ul.select-options').removeClass('show');
                });
                $(this).toggleClass('active').next('ul.select-options').toggleClass('show');
            });
        
            $listItems.click(function(e) {
                e.stopPropagation();
                $styledSelect.text($(this).text()).removeClass('active');
                $this.val($(this).attr('rel'));
                $list.find('li.is-selected').removeClass('is-selected');
                $list.find('li[rel="' + $(this).attr('rel') + '"]').addClass('is-selected');
                $list.removeClass('show');
            });
        
            $(document).click(function() {
                $styledSelect.removeClass('active');
                $list.removeClass('show');
            });
        
        });
    
        //Check all inputs by focus event
        focusEventValidation(allInputs);

        //Get filedata from work-cv
        document.querySelector('.work-cv').addEventListener('change', function(e){

            //Prevent Default
            e.preventDefault();

            return getFiledata(this, 'workcv', workcvfile, alertgigabyte, fileExtensionApproved);

        });

        //Get filedata from work-protfolio
        document.querySelector('.work-portfolio').addEventListener('change', function(e){

            //Prevent Default
            e.preventDefault();

            return getFiledata(this, 'workportfolio', workportfolio, alertgigabyte, fileExtensionApproved);

        });
       
        //Submit Form Data
        document.querySelector('.formsenddata').addEventListener('submit', function(e){

            //Prevent Default
            e.preventDefault();

            //Clean List of errors
            inputiderros = [];
            dataForm = {};

            //Enable Loading and disable submit
            enabledLoadingAndDisableBtn();
        
            //Clean all error messages
            cleanErrorMessages();
        
            //Clean all Input Class Error
            cleanInputClassError();

            //Get choose departament
            dataForm.department = document.getElementById('inputDepartment').value;

            //Set workcv filedata
            dataForm.workcv = workcvfile;

            //Set workportfolio filedata
            dataForm.workportfolio = workportfolio;

            //Check Department choose
            if(!dataForm.workcv.hasOwnProperty('file64')){

                //Send Error Message
                alert("Favor preencher todos os campos obrigatórios");

                //Disable Loading and enable submit
                disableLoadingAndEnableBtn();

                return;

            }
        
            //Get Formdata
            allInputs.forEach((valinput) => {
        
                //Validation Input
                let inputvalueerror = valinput.getAttribute('type') !== "file" ? 
                inputValidationMethod(parseInt(valinput.getAttribute('data-validate')), valinput.value, valinput) : true;

                //Set Values from Inputs
                switch(valinput.getAttribute('name')){

                    //Firstname
                    case 'work-firstname':

                        dataForm.firstname = valinput.value.trim();

                        //Check Lenght
                        if(valinput.value.trim().length > 150){

                            inputvalueerror = false;
                            valuelengtherror = true;

                        }

                        //Push input id when is not have a valid value
                        if(!inputvalueerror
                        ){
                
                            inputiderros.push(valinput.getAttribute('id'));

                        }

                    break;

                    //Lastname
                    case 'work-lastname':

                        dataForm.lastname = valinput.value.trim();

                        //Check Lenght
                        if(valinput.value.trim().length > 150){

                            inputvalueerror = false;
                            valuelengtherror = true;

                        }

                        //Push input id when is not have a valid value
                        if(!inputvalueerror
                        ){
                
                            inputiderros.push(valinput.getAttribute('id'));

                        }

                    break;

                    //Email
                    case 'work-email':

                        dataForm.email = valinput.value.trim();

                        //Check Lenght
                        if(valinput.value.trim().length > 300){

                            inputvalueerror = false;
                            valuelengtherror = true;

                        }

                        //Push input id when is not have a valid value
                        if(!inputvalueerror
                        ){
                
                            inputiderros.push(valinput.getAttribute('id'));

                        }

                    break;

                    //Phone
                    case 'work-phone':

                        dataForm.phone = valinput.value !== "" ? valinput.value.trim() : null;

                        //Check Lenght
                        if(valinput.value.trim().length > 15){

                            inputvalueerror = false;
                            valuelengtherror = true;

                        }

                    break;

                    //Location
                    case 'work-location':
                        
                        dataForm.location = valinput.value.trim();

                        //Check Lenght
                        if(valinput.value.trim().length > 150){

                            inputvalueerror = false;
                            valuelengtherror = true;

                        }

                        //Push input id when is not have a valid value
                        if(!inputvalueerror
                        ){
                
                            inputiderros.push(valinput.getAttribute('id'));

                        }
                    
                    break;

                    //Portfólio Link
                    case 'work-portfolio-link':

                        dataForm.portfoliolink = valinput.value !== "" ? valinput.value.trim() : null;

                        //Check Lenght
                        if(valinput.value.trim().length > 300){

                            inputvalueerror = false;
                            valuelengtherror = true;

                        }

                    break;

                }
        
            });
        
            //Check Errors
            if(inputiderros.length > 0){

                //Disable Loading and enable submit
                disableLoadingAndEnableBtn();
    
                dynamicErrorMessageGenerate(inputiderros, valuelengtherror);

                return;
        
            }

            //Get All data from Inputs
            if(Object.values(dataForm).length > 0){

                //Set RGPD
                dataForm.rgpd = 1;

                //Send Data
                let response = fetchData.structureTosendData(URLMETHODWORKWITHUS, dataForm, TOKEN, "POST");

                //Check Response
                response.then((valworkwithus) => {

                    //Disable Loading and enable submit
                    disableLoadingAndEnableBtn();

                    //Clean Select
                    document.getElementById('inputDepartment').value = "";
                    document.querySelector('div.form-select').textContent = "Selecione";

                    //Clean Select Options
                    [...document.querySelector('ul.select-options').children].forEach((valopt) => {

                        if(valopt.classList.contains('is-selected')){

                            valopt.classList.remove('is-selected');

                        }

                    });

                    return responseStructure(valworkwithus, allInputs);

                }).catch((error) => {

                    //Disable Loading and enable submit
                    disableLoadingAndEnableBtn();

                    throw new Error(error);

                });

            }
           
            return;

        });

    }
  
})()
